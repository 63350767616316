import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import DatoBlog from "../components/DatoBlog";

export default function Blog(props) {
  const { data } = props;

  return (
    <Layout pageTitle={data.datoCmsBlog.title}>
      <DatoBlog data={data} />
    </Layout>
  );
}

export const query = graphql`
  query($id: String!) {
    datoCmsBlog(id: { eq: $id }) {
      title
      url
      body
      authorName
      date(formatString: "LL")
      featuredImage {
        fluid(
          maxWidth: 450
          maxHeight: 550
        ) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;
