import React from "react";
import classes from "./index.module.scss";
import Image from "gatsby-image";
import BlogComponent from '../blogcomponent/blogcomponent';


const index = ({
  data: {
    datoCmsBlog: { title, body, bodyNode, featuredImage, authorName, date },
  },
}) => {
  console.log(body);
  return (
    <>
    <section className={classes.root}>
      <div className={classes.headerContainer}>
        <Image className={classes.image} fluid={featuredImage.fluid} />

        <div className={classes.titleContainer}>
        <h1 className={classes.title}>{title}</h1>          
          <span className={classes.subtitle}>
            <h6 className={classes.author}>{authorName}</h6>
            <h6 className={classes.date}>{date}</h6>
          </span>
        </div>
      </div>
      <div
        className={classes.markdown}
        dangerouslySetInnerHTML={{ __html: body }}
      />
    </section>
    <BlogComponent blogPage={true}/>
    
    </>
  );
};

export default index;
